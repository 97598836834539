import { render, staticRenderFns } from "./ModalValidaDuplicado.vue?vue&type=template&id=132cd0ad&scoped=true"
import script from "./ModalValidaDuplicado.vue?vue&type=script&lang=js"
export * from "./ModalValidaDuplicado.vue?vue&type=script&lang=js"
import style0 from "./ModalValidaDuplicado.vue?vue&type=style&index=0&id=132cd0ad&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "132cd0ad",
  null
  
)

export default component.exports